
import Vue from "vue"
import { mapState, mapActions } from "vuex"
import type { FullSession } from "@/types/api/program"

export default Vue.extend({
  data: () => ({
    visible: false,
    session: null as Nullable<FullSession>,
  }),

  async fetch () {
    await this.fetchHalls()
    await this.fetchSession()
  },

  computed: {
    // ...mapGetters("live", ["activeCode", "hall", "session"]),
    ...mapState("live", ["halls"]),
  },

  // watch: {
  //   activeCode: {
  //     immediate: true,
  //     handler (newValue) {
  //       if (newValue) this.$nuxt.$emit("comdi-code", newValue)
  //     },
  //   },
  // },

  // mounted () {
  //   this.$nuxt.$emit("comdi-code", this.activeCode)
  // },

  methods: {
    ...mapActions("live", ["fetchHalls"]),

    //   ...mapActions("live", {
    //     fetchHalls: "FETCH_HALLS", fetchSession: "FETCH_SESSION",
    //   }),

    async fetchSession () {
      const hallAlias = this.halls[0]?.alias
      if (!hallAlias) return
      let [res, err] =
        await this.$api.program.getHallLiveSession(hallAlias)
      if (err) return console.error(err)
      const sessionAlias = res?.alias
      if (!sessionAlias) return
      [res, err] = await this.$api.program.getSession(sessionAlias)
      if (err) return console.error(err)
      this.session = res || null
    },
  },
})
